export const environment = {
  "name": "red",
  "prefix": "red.",
  "type": "development",
  "apiUrl": "https://red.api.accredible.com",
  "socketUrl": "https://red.socket.accredible.com",
  "credentialNetUrl": "https://red.credential.net",
  "courseFinderUrl": "https://red.coursefinder.io",
  "accountsUrl": "https://red.accounts.accredible.com",
  "spotlightUrl": "",
  "salesCalculatorUrl": "https://red.calculator.accredible.com",
  "accredishareUrl": "https://red.accredishare.api.accredible.com",
  "stripeKey": "pk_test_cAeVkx5DhTMwelsEudggtgxC",
  "getSocialId": "aa679530",
  "bugsnagApiKey": "",
  "theme": "",
  "version": "24.24.5",
  "recaptchaV2SiteKey": "6LcOWiETAAAAADQyIj7-BSOG8KNsnYIsV84jJDfM",
  "recaptchaV3SiteKey": "6LeLojsaAAAAAB8OV2zHmQvFW66cq79i-xJqdu8j",
  "googleFontApiKey": "AIzaSyCgKPyvoy_y1MMvTtTqIex7OIBlVjbYkYE",
  "themeMicroserviceBaseUrl": "https://theme.ms.credential.net",
  "theming": false,
  "themingS3BaseUrl": "",
  "integrationsApiUrl": "https://redintegrations.api.accredible.com",
  "zoomAppClientId": "nLEeA_31TyOFFzfdL4JQQ",
  "dashboardGainsightTagKey": "AP-EGY1FB9PXTHZ-2-4",
  "recipientGainsightTagKey": "AP-DDKMXRZFO5TE-2-4",
  "unlayerProjectId": "236376"
};