import { AccredibleTableColumn } from '@accredible-frontend-v2/components/table';
import {
  AccredibleCredential,
  AccredibleEvidenceItemInsights,
  AccredibleEvidenceItemType,
  AccredibleObjectWithId,
  AccredibleTranscriptStringObject,
  MiriadaTranscriptStringObject,
} from '@accredible-frontend-v2/models';
import { AccredibleTruncateHelper } from '@accredible-frontend-v2/utils/truncate-helper';
import Bugsnag from '@bugsnag/js';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { cloneDeep, isEmpty } from 'lodash';
import { of } from 'rxjs';
import {
  EvidenceItemInsightType,
  EvidenceItemInsightTypeIcon,
  LocalEvidenceItem,
} from '../../../containers/credential/models/evidence.model';
import { Profile } from '../../../containers/profile/models/profile.model';
import { WalletCredential } from '../../../containers/profile/models/wallet-credential.model';
import { sortCredentials } from './credentials.helper';
import { credentialsFeatureKey, CredentialsState } from './credentials.reducer';

export const selectCredentialsState =
  createFeatureSelector<CredentialsState>(credentialsFeatureKey);

export const selectCredentialsStateAction = createSelector(
  selectCredentialsState,
  (state) => state.action,
);

export const selectOrganizationSettings = createSelector(
  selectCredentialsState,
  (state) => state.organizationSettings,
);

export const selectProfile = (
  profileType: 'public' | 'private',
): MemoizedSelector<object, Profile> =>
  createSelector(selectCredentialsState, (state) => state[`${profileType}Profile`]);

export const selectProfileCounts = createSelector(
  selectCredentialsState,
  (state) => state.profileCounts,
);

export const selectProfileWalletCredentials = (
  profileType: 'public' | 'private',
  all?: boolean,
): MemoizedSelector<object, WalletCredential[]> =>
  createSelector(selectCredentialsState, (state) => {
    if (all) {
      return sortCredentials([
        ...state[`${profileType}Credentials`],
        ...state[`${profileType}CredentialsCache`],
      ]);
    }

    // Here, we sort for the same reason above and then slice to only display the same number of credentials that were already rendered
    return sortCredentials([
      ...state[`${profileType}Credentials`],
      ...state[`${profileType}CredentialsCache`],
    ]).slice(0, state[`${profileType}Credentials`].length);
  });

export const selectVerifiedProfileWalletCredentials = (
  profileType: 'public' | 'private',
  includeCache = true,
): MemoizedSelector<object, WalletCredential[]> =>
  createSelector(selectCredentialsState, (state) => {
    const currentCredentials = state[`${profileType}Credentials`];
    const cacheCredentials = state[`${profileType}CredentialsCache`];
    const allCredentials = [...currentCredentials, ...cacheCredentials];

    const verifiedCredentials = allCredentials.filter(
      (credential) => !credential.isCustomCredential,
    );

    return includeCache
      ? verifiedCredentials
      : verifiedCredentials.slice(0, state[`${profileType}Credentials`].length);
  });

export const selectCredential = (
  currentUsername: string,
): MemoizedSelector<object, AccredibleCredential> =>
  createSelector(selectCredentialsState, (state) => {
    if (!!state.customCredential) {
      // Is custom credential
      // TODO: @ApiWorkaround - Map custom credential to an AccredibleCredential
      // TODO: @ApiSolution - Custom credential should have the same structure as a normal credential
      return <AccredibleCredential>{
        id: state.customCredential.id,
        uuid: state.customCredential.uuid,
        name: state.customCredential.name,
        description: state.customCredential.description,
        // A custom credential has no stored credential url, the customCredential.url is the credential image
        url: null,
        username: state.customCredential.username,
        issued_on: state.customCredential.issue_date,
        expired_on: state.customCredential.expiry_date,
        expired: state.customCredential.expiry_date
          ? new Date() > new Date(state.customCredential.expiry_date)
          : false,
        own_credential: state.customCredential.username === currentUsername,
        issuer: {
          name: state.customCredential.issuer_name,
          url: state.customCredential.course_url,
          recipient_functions: {},
        },
        recipient: {
          name: state.customCredential.recipient_name,
        },
        group: {},
        opengraph_image: state.customCredential.url,
        twittercard_image: state.customCredential.url,
        isCustomCredential: true,
      };
    }

    // Return credential, mapping responsive menu items keys
    return <AccredibleCredential>{
      ...state.credential,
      group: {
        ...state.credential.group,
        transcript_viewable: !!state.credential.group.transcript,
        // TODO: show_job_insights shouldn't come as true from the API if there's actually no job insights
        show_job_insights:
          state.credential.group.show_job_insights && !isEmpty(state.credential.job_insights),
      },
      issuer: {
        ...state.credential.issuer,
        recipient_functions: {
          // If the recipient_functions are null default to public/private
          share: state.credential.issuer.recipient_functions?.sharing || 'public',
          pdf: state.credential.issuer.recipient_functions?.download_pdf || 'public',
          badge: state.credential.issuer.recipient_functions?.download_badge || 'public',
          email: state.credential.issuer.recipient_functions?.email_share || 'public',
          embed: state.credential.issuer.recipient_functions?.embed || 'public',
          privacy: state.credential.issuer.recipient_functions?.change_privacy || 'private',
          requestNameChange: state.credential.issuer.recipient_functions?.change_name || 'private',
          transcriptLetter:
            state.credential.issuer.recipient_functions?.transcript_viewable || 'private',
          printPdf: state.credential.issuer.recipient_functions?.download_pdf_print || 'public',
          addToLinkedIn: state.credential.issuer.recipient_functions?.add_to_linkedin || 'private',
          shareToLinkedIn:
            state.credential.issuer.recipient_functions?.share_to_linkedin || 'private',
          addEvidence: state.credential.issuer.recipient_functions?.add_evidence || 'private',
          referAFriend: state.credential.issuer.recipient_functions?.refer_a_friend || 'private',
        },
      },
      job_insights: state.credential.job_insights,
    };
  });

export const selectTranscript = createSelector(
  selectCredentialsState,
  (state) => state.credential.group.transcript,
);

export const selectCredentialUser = createSelector(
  selectCredentialsState,
  (state) => state.credentialUser,
);

export const selectEvidenceItems = createSelector(selectCredentialsState, (state) => {
  let evidenceItems: LocalEvidenceItem[] = [];

  state.evidenceInsights.forEach((evidenceInsight) => {
    const evidenceItem = cloneDeep(
      <LocalEvidenceItem>(
        (<AccredibleCredential>state.credential).evidence_items.find(
          (item) => item.id === evidenceInsight.id,
        )
      ),
    );

    if (evidenceItem) {
      evidenceItem.subtitle1 = {
        icon: null,
        text: null,
      };
      evidenceItem.subtitle2 = {
        icon: null,
        text: null,
      };
      evidenceItem.isMiriada = false;

      switch (evidenceItem.type) {
        case AccredibleEvidenceItemType.YOUTUBE_VIDEO:
          const youtubeUrls = createYoutubeUrls(evidenceItem.link_url);
          if (youtubeUrls) {
            evidenceItem.youtubeImageUrl = youtubeUrls.youtubeImageUrl;
            evidenceItem.youtubeEmbedUrl = youtubeUrls.youtubeEmbedUrl;
          }

          const durationText = evidenceInsight.insight.find(
            (insight) => insight.type === EvidenceItemInsightType.DURATION,
          )?.text;
          if (durationText) {
            evidenceItem.subtitle1.icon = EvidenceItemInsightTypeIcon.DURATION;
            evidenceItem.subtitle1.text = durationText;
            evidenceItem.subtitle2.icon = 'movie';
            evidenceItem.subtitle2.text = 'Video';
          } else {
            evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          }

          break;

        case AccredibleEvidenceItemType.GRADE:
          // Miriada - Check if evidence is Miriada
          const isMiriada = evidenceInsight.insight.find(
            (insight) => insight.type === EvidenceItemInsightType.FULL_SIZE_DESCRIPTION,
          );
          if (isMiriada) {
            evidenceItem.subtitle1.text = isMiriada.text;
            evidenceItem.subtitle2 = null;
          } else {
            const percentile = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.PERCENTILE,
            );

            const averageScoreText = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.AVERAGE_SCORE,
            ).text;

            if (percentile) {
              evidenceItem.subtitle1.icon = EvidenceItemInsightTypeIcon.PERCENTILE;
              evidenceItem.subtitle1.text = evidenceInsight.insight.find(
                (insight) => insight.type === EvidenceItemInsightType.PERCENTILE,
              ).text;
              evidenceItem.subtitle2.icon = EvidenceItemInsightTypeIcon.AVERAGE_SCORE;
              evidenceItem.subtitle2.text = averageScoreText;
            } else {
              evidenceItem.subtitle1.icon = EvidenceItemInsightTypeIcon.AVERAGE_SCORE;
              evidenceItem.subtitle1.text = averageScoreText;
            }
          }
          break;

        case AccredibleEvidenceItemType.TRANSCRIPT:
          evidenceItem.subtitle1.icon = EvidenceItemInsightTypeIcon.TOP_SCORE;
          evidenceItem.subtitle1.text = evidenceInsight.insight.find(
            (insight) => insight.type === EvidenceItemInsightType.TOP_SCORE,
          ).text;
          evidenceItem.subtitle2.icon = EvidenceItemInsightTypeIcon.ITEMS;
          evidenceItem.subtitle2.text = evidenceInsight.insight
            .find((insight) => insight.type === EvidenceItemInsightType.ITEMS)
            .text.split(',')[0];

          evidenceItem.evidenceTranscriptData = createEvidenceTranscriptTableData(
            <AccredibleTranscriptStringObject[]>evidenceItem.string_object,
          );
          break;

        case AccredibleEvidenceItemType.STATIC_TEXT:
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          break;

        case AccredibleEvidenceItemType.URL_NO_SCREENSHOT:
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          break;

        case AccredibleEvidenceItemType.IMAGE:
        case AccredibleEvidenceItemType.URL:
        case AccredibleEvidenceItemType.PDF:
        case AccredibleEvidenceItemType.GENERIC_FILE:
          if (
            evidenceItem.file_type?.indexOf('image/') > -1 &&
            evidenceItem.file_type?.indexOf('svg') === -1
          ) {
            // It's an image file
            evidenceItem.type = AccredibleEvidenceItemType.IMAGE;
            evidenceItem.subtitle1.text = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.DEFAULT,
            ).text;
            evidenceItem.subtitle2 = null;
          } else if (evidenceItem.file_type === 'application/pdf') {
            // It's a pdf file
            evidenceItem.type = AccredibleEvidenceItemType.PDF;
            evidenceItem.subtitle1.icon = EvidenceItemInsightTypeIcon.WORD_COUNT;
            evidenceItem.subtitle1.text = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.WORD_COUNT,
            ).text;
            const items = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.ITEMS,
            );
            const timeToRead = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.TIME_TO_READ,
            );
            evidenceItem.subtitle2.icon = !!items
              ? (evidenceItem.subtitle2.icon = EvidenceItemInsightTypeIcon.ITEMS)
              : EvidenceItemInsightTypeIcon.TIME_TO_READ;
            evidenceItem.subtitle2.text = !!items ? items.text : timeToRead.text;
          } else {
            // It's a generic file
            evidenceItem.subtitle1.text = evidenceInsight.insight.find(
              (insight) => insight.type === EvidenceItemInsightType.DEFAULT,
            ).text;
            evidenceItem.subtitle2 = null;
          }
          break;

        // Miriada EvidenceItemType - start
        case AccredibleEvidenceItemType.MIRIADA_VIDEO:
          evidenceItem.isMiriada = true;
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          evidenceItem.subtitle2 = null;

          const miriadaData: string = <string>evidenceItem.string_object;
          if (miriadaData.includes('youtube')) {
            evidenceItem.type = AccredibleEvidenceItemType.YOUTUBE_VIDEO;
            const divElement = document.createElement('div');
            divElement.innerHTML = miriadaData;
            const url = (<HTMLElement>divElement.firstChild).getAttribute('src');
            const youtubeUrls = createYoutubeUrls(url);
            if (youtubeUrls) {
              evidenceItem.youtubeImageUrl = youtubeUrls.youtubeImageUrl;
              evidenceItem.youtubeEmbedUrl = youtubeUrls.youtubeEmbedUrl;
            }
          } else if (miriadaData.includes('vimeo')) {
            evidenceItem.vimeoEmbedUrl = miriadaData.split('"')[1];
          } else {
            console.error('Invalid video data in evidenceItem.string_object');
          }
          break;

        case AccredibleEvidenceItemType.MIRIADA_OUTLINE:
          const transcriptData = <MiriadaTranscriptStringObject>evidenceItem.string_object;
          evidenceItem.isMiriada = true;
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          evidenceItem.subtitle2 = null;
          evidenceItem.miriadaTranscriptData =
            createMiriadaEvidenceTranscriptTableData(transcriptData);
          evidenceItem.miriadaTableColumns = createMiriadaTranscriptionTableColumns(transcriptData);
          break;

        case AccredibleEvidenceItemType.MIRIADA_TEACHER_OUTLINE:
          evidenceItem.isMiriada = true;
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          evidenceItem.subtitle2 = null;
          break;

        case AccredibleEvidenceItemType.MIRIADA_PARTICIPATION:
          evidenceItem.isMiriada = true;
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          evidenceItem.subtitle2 = null;
          break;

        case AccredibleEvidenceItemType.MIRIADA_CUSTOM_EVIDENCE_TYPE:
          evidenceItem.isMiriada = true;
          evidenceItem.subtitle1.text = getEvidenceText(evidenceInsight);
          evidenceItem.subtitle2 = null;
          break;
        // Miriada EvidenceItemType - end

        // Not supported evidence type
        default:
          Bugsnag.notify('Evidence type not supported: ' + evidenceItem.type);
          return;
      }
    }

    evidenceItems = [...evidenceItems, evidenceItem];
  });

  return evidenceItems;
});

export const selectCredentialRecommendations = createSelector(selectCredentialsState, (state) => {
  return state.courseRecommendations;
});

export const selectCredentialPathwayEnrolments = createSelector(selectCredentialsState, (state) => {
  return state.pathwayEnrolments;
});

// Exported to allow for testing in the credentials.selectors.spec.ts
export const createYoutubeUrls = (
  url: string,
): { youtubeImageUrl: string; youtubeEmbedUrl: string } => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  // A valid youtube embed video id needs to have 11 characters length
  if (match && match[2].length === 11) {
    return {
      youtubeImageUrl: 'https://img.youtube.com/vi/' + match[2] + '/mqdefault.jpg',
      youtubeEmbedUrl: 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1',
    };
  } else {
    console.log('Evidence youtube video url (' + url + '), is not valid.');
    return null;
  }
};

// Exported to allow for testing in the credentials.selectors.spec.ts
export const getEvidenceText = (evidenceInsight: AccredibleEvidenceItemInsights): string => {
  const fullSizeDescription = evidenceInsight.insight.find(
    (insight) => insight.type === EvidenceItemInsightType.FULL_SIZE_DESCRIPTION,
  );
  if (fullSizeDescription) {
    return AccredibleTruncateHelper.truncate(fullSizeDescription.text, 100);
  } else {
    const defaultInsight = evidenceInsight.insight.find(
      (insight) => insight.type === EvidenceItemInsightType.DEFAULT,
    );
    if (defaultInsight) {
      return defaultInsight.text;
    }

    Bugsnag.notify('Evidence insight does not have a default insight. Id: ' + evidenceInsight.id);
    return '';
  }
};

const createEvidenceTranscriptTableData = (
  data: AccredibleTranscriptStringObject[],
): AccredibleObjectWithId[] => {
  const tableRows = <AccredibleObjectWithId[]>[];
  data.forEach((el, index) => {
    tableRows.push({
      id: index + 1,
      ...el,
    });
  });
  return tableRows;
};

// Miriada transcript functions
const createMiriadaTranscriptionTableColumns = (
  data: MiriadaTranscriptStringObject,
): AccredibleTableColumn[] => {
  return <AccredibleTableColumn[]>[
    { label: of(data.headers[0]), def: 'title' },
    { label: of(data.headers[1]), def: 'date' },
  ];
};

const createMiriadaEvidenceTranscriptTableData = (
  data: MiriadaTranscriptStringObject,
): AccredibleObjectWithId[] => {
  const tableRows = <AccredibleObjectWithId[]>[];
  data.content.forEach((el, index) => {
    const item = <AccredibleObjectWithId>{};
    item.id = index + 1;
    el.forEach((str, i) => {
      if (i === 0) {
        item['title'] = str;
      } else {
        // index === 1
        item['date'] = str;
      }
    });
    tableRows.push(item);
  });
  return tableRows;
};
